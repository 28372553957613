import React, { createRef, useEffect, useMemo } from "react";
import importAll from "import-all.macro";
import { Toaster } from "react-hot-toast";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import useSound from "use-sound";
import { css } from "styled-components";
import { InlineLoading, Flex, Modal } from "@zeal/web-ui";
import NewOrderNotification from "@zeal/web-ui/src/assets/sounds/new_order.wav";
import { getFireBaseToken, onMessageListener } from "../firebase";
import { path as gifFriendPath } from "../GiftFriend/pathOptions";
import Login from "./Login/Login";
import AppContainer from "./AppContainer";
import ErrorBoundary from "./ErrorBoundary";
import { ErrorFallback, NotFound } from "./Components";
import ForgotPassword from "./ForgotPassword/forgotPassword";
import useSidebarSettings from "./data/useSidebarSettings";
import { useLoggedIn } from "./ACL/useLogin";
import openToaster from "./Toastr/openToastr";
import useSaveToken from "./data/useFirebaseNotifications";
import { queryClient } from "./QueryClient";
import { generatedKeys } from "./data";
import { useGiftFriendRequestStatus } from "@data/GiftFriend";
import { useModalStore } from "./store/modalStore";
import MagicLinkRoutes from "./MagicLinkLogin/Routes";
import { useFetchTranslation } from "./hooks";
const routes = Object.values(importAll.sync("../**/pathEntry.js"));
const lastNotificationRef = createRef();
const merchantRedirection = {
  freemium: "/freemium",
  premium: "/premium",
  cardlink: "/cardlink",
  null: "/dashboard",
  default: "/dashboard"
};
const LazyLoadingSuspenseFallback = () => {
  return (
    //TODO: REPLACE with ui/ux team
    /* @__PURE__ */ React.createElement(Flex, { justify: "center", align: "center", className: "h-screen" }, /* @__PURE__ */ React.createElement(InlineLoading, null))
  );
};
export default function AppRoutes() {
  const modalStore = useModalStore((state) => state);
  const refetchOrders = () => {
    queryClient.invalidateQueries({
      queryKey: generatedKeys?.orders?.base
    });
  };
  const history = useHistory();
  const [playNewOrderNotification, { sound }] = useSound(NewOrderNotification, {
    loop: true
  });
  const { isTranslationsLoading } = useFetchTranslation("common");
  React.useState({
    routes
  });
  const { isLoggedIn, isNIMerchant, merchantType } = useLoggedIn();
  const { mutate: saveToken } = useSaveToken();
  const { sidebarSettings, isLoading: isLoadingSidebarSettings } = useSidebarSettings();
  const allowedRoutes = sidebarSettings || {};
  const { data: giftFriendStatusData } = useGiftFriendRequestStatus();
  const isGiftFriendEnabled = giftFriendStatusData?.data?.is_enabled;
  const NiExcludedRoutes = ["/feedback"];
  const isAllowedRoutesEmpty = Object.keys(allowedRoutes).length === 0;
  const filteredRoutes = routes.filter(
    ({ default: { path } }) => !NiExcludedRoutes.includes(path) && isNIMerchant || !isNIMerchant
  ).filter(
    ({ default: { apiKey } }) => allowedRoutes[apiKey]?.visible || isAllowedRoutesEmpty
  ).filter(
    ({ default: { path } }) => path === gifFriendPath ? isGiftFriendEnabled : true
  );
  const appRoutes = filteredRoutes.map(({ default: { routes: routes2, path } }) => {
    return /* @__PURE__ */ React.createElement(Route, { key: path, path, component: routes2 });
  });
  const Loading = useMemo(
    () => /* @__PURE__ */ React.createElement(Flex, { justify: "center", align: "center", className: "h-screen" }, /* @__PURE__ */ React.createElement(
      InlineLoading,
      {
        screenHeight: css`
						height: calc(100vh - var(--main-headerbar-height));
					`
      }
    )),
    []
  );
  useEffect(() => {
    if (isLoggedIn) {
      getFireBaseToken().then((token) => {
        if (!!token) {
          saveToken(token);
        }
      });
    }
  }, [isLoggedIn]);
  const dashboardURL = merchantRedirection[merchantType];
  const isDashboardURLAllowed = dashboardURL && filteredRoutes.some(({ default: { path } }) => path === dashboardURL);
  const loggedInRedirection = (isDashboardURLAllowed || isAllowedRoutesEmpty ? dashboardURL : filteredRoutes[0]?.default?.path) ?? merchantRedirection.default;
  const redirectUrl = isLoggedIn ? loggedInRedirection : "/login";
  const showNotification = (payload) => {
    playNewOrderNotification({});
    console.log(payload);
    let messageBody = {};
    try {
      messageBody = JSON.parse(payload?.notification?.body || "{}");
    } catch (error) {
      messageBody = payload?.notification?.body || "";
    }
    const orderId = messageBody?.order_uuid;
    if (lastNotificationRef.current == orderId) {
      return;
    }
    refetchOrders();
    const url = orderId ? `/orders/list/${orderId}` : "/orders";
    openToaster({
      message: payload?.notification?.title || "",
      type: "success",
      duration: 1e3 * 60 * 30,
      // 30 minutes
      onClick: () => {
        sound?.pause?.();
        history.push(url);
      }
    });
    lastNotificationRef.current = orderId;
  };
  const channel = new BroadcastChannel("notification_channel");
  channel.onmessage = (event) => {
    showNotification(event.data.key);
  };
  onMessageListener().then((payload) => {
    showNotification(payload);
  }).catch((err) => console.log("failed: ", err));
  if (isLoggedIn && isLoadingSidebarSettings || isTranslationsLoading) {
    return Loading;
  }
  return /* @__PURE__ */ React.createElement(ErrorBoundary, { fallback: /* @__PURE__ */ React.createElement(ErrorFallback, null) }, /* @__PURE__ */ React.createElement(Toaster, null), /* @__PURE__ */ React.createElement(Modal, { ...modalStore }, modalStore.children), /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, { path: "/login", component: Login }), /* @__PURE__ */ React.createElement(Route, { path: "/forgot-password", component: ForgotPassword }), /* @__PURE__ */ React.createElement(Route, { path: "/free-tier", component: MagicLinkRoutes }), /* @__PURE__ */ React.createElement(Route, null, /* @__PURE__ */ React.createElement(AppContainer, null, /* @__PURE__ */ React.createElement(React.Suspense, { fallback: LazyLoadingSuspenseFallback() }, /* @__PURE__ */ React.createElement(Switch, null, appRoutes, /* @__PURE__ */ React.createElement(Route, { path: "/", exact: true }, /* @__PURE__ */ React.createElement(Redirect, { push: true, to: redirectUrl })), /* @__PURE__ */ React.createElement(Route, { path: "*", component: NotFound })))))));
}
