import { TPathOptions } from '../App/types/types';

const cardLinkDashboardPathOptions: TPathOptions = {
	apiKey: 'cardlink_dashboard',
	path: '/cardlink',
	label: 'home',
	iconName: 'house',
	menuOrder: 1,
	ownApp: false,
};

export default cardLinkDashboardPathOptions;
