import { TPathOptions } from '../App/types/types';

const ordersStatusPathOptions: TPathOptions = {
	apiKey: 'admins:order_statuses',
	path: '/orders-status-history',
	label: 'orders_status_history',
	iconName: 'history',
	menuOrder: 2,
	ownApp: true,
};

export default ordersStatusPathOptions;
