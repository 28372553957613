import { TPathOptions, RouteFlags } from '../App/types/types';

const ordersPathOptions: TPathOptions = {
	apiKey: 'menu',
	path: '/orders',
	label: 'orders',
	iconName: 'list',
	menuOrder: 2,
	ownApp: true,
	subMenu: [
		{
			path: '/orders/list',
			label: 'orders_list',
			featureApiKey: 'menu',
		},
		{
			path: '/orders/calendar',
			label: 'orders_calendar',
			isExcluded: ({ businessProperties }) => !businessProperties?.booking,
			featureApiKey: 'menu',
		},
	],
};

export default ordersPathOptions;
