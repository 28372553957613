import { TPathOptions } from '../App/types/types';

const activityLogPathOptions: TPathOptions = {
	apiKey: 'admins:activity_log',
	path: '/activity-log',
	label: 'activity_log',
	iconName: 'history',
	menuOrder: 3,
	ownApp: true,
};

export default activityLogPathOptions;
