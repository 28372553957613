import { TPathOptions } from '../App/types/types';

const dashboardPathOptions: TPathOptions = {
	apiKey: 'dashboard',
	path: '/dashboard',
	label: 'dashboard',
	iconName: 'house',
	menuOrder: 1,
	ownApp: false,
	flag: 'enableDashboardLanding',
};

export default dashboardPathOptions;
