import { TPathOptions } from '../App/types/types';

const galleryPathOptions: TPathOptions = {
	apiKey: 'gallery',
	exact: false,
	path: '/gallery',
	label: 'gallery',
	iconName: 'image',
	menuOrder: 4,
	ownApp: true,
	subMenu: [
		{
			path: '/gallery/photos',
			label: 'photos',
		},
		{
			path: '/gallery/videos',
			label: 'videos',
		},
	]
};

export default galleryPathOptions;
