import { TPathOptions } from '../App/types/types';

const campaignsPathOptions: TPathOptions = {
	apiKey: 'campaigns',
	path: '/campaigns',
	label: 'campaigns',
	iconName: 'bullhorn',
	menuOrder: 3,
	ownApp: true,
	subMenu: [
		{
			featureApiKey: 'campaigns',
			label: 'campaigns',
			path: '/campaigns/list',
		},
		{
			label: 'segments',
			path: '/campaigns/segments',
		},
	],
};

export default campaignsPathOptions;
