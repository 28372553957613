import { kebabCase } from 'lodash-es';

export function plural(word: string) {
	const plural: Record<string, string> = {
		'(quiz)$': '$1zes',
		'^(ox)$': '$1en',
		'([m|l])ouse$': '$1ice',
		'(matr|vert|ind)ix|ex$': '$1ices',
		'(x|ch|ss|sh)$': '$1es',
		'([^aeiouy]|qu)y$': '$1ies',
		'(hive)$': '$1s',
		'(?:([^f])fe|([lr])f)$': '$1$2ves',
		'(shea|lea|loa|thie)f$': '$1ves',
		sis$: 'ses',
		'(tomat|potat|ech|her|vet)o$': '$1oes',
		'(bu)s$': '$1ses',
		'(alias)$': '$1es',
		'(octop)us$': '$1i',
		'(ax|test)is$': '$1es',
		'(us)$': '$1es',
		'([^s]+)$': '$1s',
	};

	const irregular: Record<string, string> = {
		move: 'moves',
		foot: 'feet',
		goose: 'geese',
		sex: 'sexes',
		child: 'children',
		man: 'men',
		tooth: 'teeth',
		person: 'people',
	};

	const uncountable = [
		'sheep',
		'fish',
		'deer',
		'moose',
		'series',
		'species',
		'money',
		'rice',
		'information',
		'equipment',
		'bison',
		'cod',
		'offspring',
		'pike',
		'salmon',
		'shrimp',
		'swine',
		'trout',
		'aircraft',
		'hovercraft',
		'spacecraft',
		'sugar',
		'tuna',
		'you',
		'wood',
	];
	// save some time in the case that singular and plural are the same
	if (uncountable.indexOf(word.toLowerCase()) >= 0) {
		return word;
	}
	// check for irregular forms
	for (const w in irregular) {
		const pattern = new RegExp(`${w}$`, 'i');
		const replace = irregular[w];
		if (pattern.test(word)) {
			return word.replace(pattern, replace);
		}
	}
	// check for matches using regular expressions
	for (const reg in plural) {
		const pattern = new RegExp(reg, 'i');
		if (pattern.test(word)) {
			return word.replace(pattern, plural[reg]);
		}
	}
	return word;
}

export const getCollectionName = (moduleName: string) => {
	const formatted = kebabCase(plural(moduleName.replace(/\.json$/, '')));

	const prefix = 'web-vendor';
	return `${prefix}-${formatted}`;
};
