import { TPathOptions } from '../App/types/types';

const subscriptionsPathOptions: TPathOptions = {
	apiKey: 'subscriptions',
	path: '/subscriptions',
	label: 'subscriptions',
	iconName: 'userGroup',
	menuOrder: 7,
	ownApp: true,
};

export default subscriptionsPathOptions;
