import { TPathOptions } from '../App/types/types';

const baristasPathOptions: TPathOptions = {
	apiKey: 'assistant_profiles',
	path: '/baristas',
	label: 'baristas',
	iconName: 'userGroup',
	menuOrder: 8,
	ownApp: false,
};

export default baristasPathOptions;
