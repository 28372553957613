import { TPathOptions } from '../App/types/types';

const settingsPathOptions: TPathOptions = {
	apiKey: 'settings',
	path: '/settings',
	label: 'settings',
	iconName: 'cog',
	menuOrder: false,
	ownApp: false,
};

export default settingsPathOptions;
