import { TPathOptions, RouteFlags } from '../App/types/types';

const giftCardPathOptions: TPathOptions = {
	apiKey: 'gift_card',
	path: '/giftcard',
	label: 'gift_card',
	iconName: 'giftCard',
	menuOrder: 6,
	ownApp: true,
	subMenu: [
		{
			path: '/giftcard/list',
			label: 'list',
		},
		{
			path: '/giftcard/reports',
			label: 'reports',
		},
	],
};

export default giftCardPathOptions;
