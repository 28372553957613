import { FeatureKey } from '@app/FeatureControl/types';
import { IconNames } from '@zeal/web-ui';

export enum RouteFlags {
	SHOW_REQUESTS_DISABLED = 'SHOW_REQUESTS_DISABLED',
	SHOW_REDEMPTIONS_DISABLED = 'SHOW_REDEMPTIONS_DISABLED',
	SHOW_RECEIPTS_DISABLED = 'SHOW_RECEIPTS_DISABLED',
	NI_MERCHANT = 'NI_MERCHANT',
	GIFT_FRIEND_DISABLED = 'GIFT_FRIEND_DISABLED',
}

type MenuSections = 'cardlink';

export type TPathOptions = {
	apiKey: FeatureKey;
	exact?: boolean;
	menuOrder: number | false;
	ownApp: boolean;
	path: string;
	label: string;
	iconName: IconNames;
	subMenu?: SubMenuOptions[];
	excludedFor: RouteFlags[];
	flag?: string;
	isFeatured?: boolean;
	menuSection?: MenuSections;
};

export type SubMenuOptions = {
	label: string;
	path: string;
	excludedFor?: RouteFlags[];
	isExcluded?: ({ businessProperties }: { businessProperties: any }) => boolean;
	featureApiKey?: FeatureKey;
	isFeatured?: boolean;
};
