import { TPathOptions, RouteFlags } from '../App/types/types';

const teamsPathOptions: TPathOptions = {
	apiKey: 'teams',
	exact: false,
	menuOrder: 3,
	ownApp: false,
	path: '/teams',
	label: 'teams',
	iconName: 'userGroup',
	subMenu: [
		{
			label: 'administrators',
			featureApiKey: 'teams:team_app',
			path: '/teams/dashboard',
		},
		{
			label: 'teams_app',
			path: '/teams/app',
			featureApiKey: 'teams:team_app',
			excludedFor: [RouteFlags.NI_MERCHANT],
		},
		{
			label: 'manage_roles',
			featureApiKey: 'teams:manage_roles',
			path: '/teams/roles',
		}
		
	],
};

export default teamsPathOptions;
