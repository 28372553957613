import { TPathOptions, RouteFlags } from '../App/types/types';

const recommendationsPathOptions: TPathOptions = {
	apiKey: 'recommendations',
	path: '/recommendations',
	label: 'recommendations',
	iconName: 'thumbsUp',
	menuOrder: 7,
	ownApp: true,
};

export default recommendationsPathOptions;
