import React from 'react';

import { ApiRequest } from '@zeal/zeal-lib';

import { useQuery, queryClient } from '../../App/QueryClient';
import { generatedKeys } from '../../App/data';

import { TSidebarSettings, TSidebarSettingsEntry } from './types';
import { useLoggedIn } from '../ACL/useLogin';
import { getQueryKey } from './useQueryKey';
import { useAuthStore } from '@app/store/authStore';

const { sidebarSettings } = generatedKeys;

async function apiSidebarSettings(): Promise<TSidebarSettings> {
	return new ApiRequest('v3/sidebar-settings')
		.addHeader('Accept', 'application/json')
		.GET()
		.then((payload) => {
			const data = payload?.data as TSidebarSettingsEntry[] | undefined;
			return {
				...mapSidebarSettings(data),
			};
		});
}

export default function useSidebarSettings() {
	const { isLoggedIn } = useLoggedIn();

	const q = useQuery({
		queryFn: apiSidebarSettings,
		queryKey: sidebarSettings?.all?.({
			id: useAuthStore.getState().loginVault?.id,
		}),
		enabled: isLoggedIn && !!useAuthStore.getState().loginVault?.id,
		staleTime: Infinity,
	});

	React.useDebugValue(q);

	return {
		...q,
		sidebarSettings: q.data as TSidebarSettings,
	};
}

const getFeatureName = (
	feature: Pick<TSidebarSettingsEntry, 'key' | 'parent'>
) => {
	if (!feature) {
		return '';
	}

	const featureName: string = feature.parent
		? `${getFeatureName(feature.parent)}:${feature.key}`
		: feature.key;

	return featureName;
};

function mapSidebarSettings(
	sidebarSettings?: TSidebarSettingsEntry[]
): TSidebarSettings {
	return (
		sidebarSettings
			?.filter((item) => item?.visible)
			.reduce<TSidebarSettings>(
				(acc, item) => ({
					...acc,
					[getFeatureName(item)]: item,
				}),
				{}
			) || {}
	);
}

export const getSidebarSettings = () => {
	return (queryClient.getQueryData(getQueryKey(sidebarSettings?.all?.({})!)) ||
		{}) as TSidebarSettings;
};
