import { TPathOptions } from '../App/types/types';

const premiumTransactionsPathOptions: TPathOptions = {
	apiKey: 'premium_transactions',
	exact: false,
	menuOrder: 3,
	ownApp: false,
	path: '/premium-transactions',
	label: 'transactions',
	excludedFor: [],
	iconName: 'creditCard',
	subMenu: [
		{
			featureApiKey: 'premium_transactions:transactions_overview',
			label: 'transactions_overview',
			path: '/premium-transactions/transactions',
		},
		{
			featureApiKey: 'premium_transactions:transactions_downloads',
			label: 'downloads',
			path: '/premium-transactions/downloads',
		},
	],
};

export default premiumTransactionsPathOptions;
