import { TPathOptions } from '../App/types/types';

const freemiumTeamsPathOptions: TPathOptions = {
	apiKey: 'freemium_administration',
	exact: false,
	menuOrder: 3,
	ownApp: false,
	path: '/administration',
	label: 'teams',
	iconName: 'userGroup',
	subMenu: [
		{
			featureApiKey: 'freemium_administration:freemium_administration',
			label: 'administrators',
			path: '/administration/administrators',
		},
		{
			featureApiKey: 'freemium_administration:freemium_teams',
			label: 'team_members',
			path: '/administration/teams',
		},
	],
};

export default freemiumTeamsPathOptions;
