import React, { memo, useMemo } from 'react';
import { useAppState } from '../../store/appStore';
import { TPathOptions } from '../../types/types';
import { groupBy } from 'lodash-es';
import { useFilteredMenuLinks } from './useFilteredMenuLinks';
import { MenuLinksSection } from './MenuLinksSection';
import RouteMenuLink from './RouteMenuLink';
import SectionDivider from '../SectionDivider';

export type MenuLinksProps = {
	appMainMenus: TPathOptions[];
	ownAppMenus: TPathOptions[];
	sectionedMenus: TPathOptions[];
};

function MenuLinks(props: MenuLinksProps) {
	const { appMainMenus, ownAppMenus, sectionedMenus } = props;

	const { customAppName, isCustomAppActive } = useAppState();

	const { filteredMainMenu, filteredOwnApp, filteredSectioned } =
		useFilteredMenuLinks({
			appMainMenus,
			ownAppMenus,
			sectionedMenus,
		});

	const MainMenuMarkup = filteredMainMenu.map((i) => (
		<RouteMenuLink key={i.path} route={i} />
	));

	const OwnAppMenuMarkup = (
		<>
			{isCustomAppActive && (
				<MenuLinksSection title={customAppName || ''}>
					{filteredOwnApp.map((i) => (
						<RouteMenuLink key={i.path} route={i} />
					))}
				</MenuLinksSection>
			)}
		</>
	);

	const groupedSectioned = useMemo(
		() => Object.entries(groupBy(filteredSectioned, 'menuSection')),
		[filteredSectioned]
	);
	const OtherGroupsMenuMarkup = groupedSectioned.map(
		([groupKey, groupMenuItems], index) => {
			const isLastSection = index === groupedSectioned.length - 1;
			return (
				<>
					<MenuLinksSection key={groupKey} title={groupKey}>
						{groupMenuItems.map((i) => (
							<RouteMenuLink key={i.path} route={i} />
						))}
					</MenuLinksSection>
					{!isLastSection && <SectionDivider />}
				</>
			);
		}
	);

	return (
		<>
			{MainMenuMarkup}
			{isCustomAppActive && <SectionDivider />}
			{OwnAppMenuMarkup}
			{groupedSectioned.length > 0 && <SectionDivider />}
			{OtherGroupsMenuMarkup}
		</>
	);
}

export default memo(MenuLinks);
