import React from 'react';
import { Flex, InlineLoading } from '@zeal/web-ui';
import { css } from 'styled-components';

export function AppContainerLoading() {
	return (
		<Flex
			justify="center"
			align="center"
			CSS={{
				height: '100vh',
			}}
		>
			<InlineLoading
				screenHeight={css`
					height: calc(100vh - var(--main-headerbar-height));
				`}
			/>
		</Flex>
	);
}
