import { TPathOptions } from '../App/types/types';

const cardlinkPathOptions: TPathOptions = {
	apiKey: 'cardlink_dashboard',
	path: '/cardlink-activity',
	label: 'cardlink_activity',
	iconName: 'fileMedicalAlt',
	menuOrder: 2,
	ownApp: false,
	menuSection: 'cardlink',
};

export default cardlinkPathOptions;
