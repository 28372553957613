import { Flex, Label, Icon } from '@zeal/web-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function VerificationSent() {
	const { t } = useTranslation('magicLink');

	return (
		<Flex
			isColumn
			gap="12"
			align="center"
			justify="center"
			styles={{
				height: '500px',
			}}
		>
			<Icon
				iconName="envelope"
				style={{
					width: '120px',
					height: '120px',
				}}
				variant="light"
				colorVariant="secondary"
			/>
			<Flex
				isColumn
				gap="2"
				align="center"
				justify="center"
				styles={{
					width: '504px',
				}}
			>
				<Label.Big600 variant="default" textAlign="center">
					{t('step_4_title')}
				</Label.Big600>
				<Label.Thin300 variant="subdued" textAlign="center">
					{t('step_4_description')}
				</Label.Thin300>
			</Flex>
		</Flex>
	);
}
