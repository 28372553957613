import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { NotFound } from '../Components';
import FormContent from './components/form/FormContent';
import Login from './components/form/Login';
import MagicLinkLayout from './MagicLinkLayout';

export default function MagicLinkRoutes() {
	return (
		<MagicLinkLayout>
			<Switch>
				<Route exact path="/free-tier/sign-up/:token" component={FormContent} />

				<Route exact path="/free-tier/login" component={Login} />

				<Route path="*" component={NotFound} />
			</Switch>
		</MagicLinkLayout>
	);
}
