import { TPathOptions, RouteFlags } from '../App/types/types';

const customersPathOptions: TPathOptions = {
	apiKey: 'customers',
	path: '/customers-management',
	label: 'customers',
	iconName: 'user',
	menuOrder: 5,
	ownApp: false,
	subMenu: [
		{
			featureApiKey: 'customers',
			path: '/customers-management',
			label: 'customers',
		},
		{
			path: '/customers-management/requests-verification',
			label: 'requests',
			excludedFor: [RouteFlags.SHOW_REQUESTS_DISABLED],
		},
	],
};

export default customersPathOptions;
