import React from "react";
import { Redirect } from "react-router-dom";
function UnAuthorized(props) {
  const { unauthorizedRender, unauthorizedComponent, unauthorizedRedirect } = props;
  if (unauthorizedRender) {
    return unauthorizedRender?.();
  }
  if (unauthorizedRender) {
    const UnAuth = unauthorizedComponent;
    return /* @__PURE__ */ React.createElement(UnAuth, { ...props });
  }
  return /* @__PURE__ */ React.createElement(Redirect, { to: redirectToState(unauthorizedRedirect) });
}
const redirectToState = (pathname = "/login") => ({
  pathname,
  state: {
    unauthorizedRedirect: true,
    shouldGoBack: true
  }
});
export default React.memo(UnAuthorized);
