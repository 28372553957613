//@ts-ignore
import importAll from 'import-all.macro';
import { sortBy } from 'lodash-es';
import { TPathOptions } from '@app/types/types';

type TPathOptionsEntry = {
	default: TPathOptions;
};

const appMenuEntries = {
	ownAppMenus: [] as TPathOptions[],
	mainAppMenus: [] as TPathOptions[],
	sectionedMenus: [] as TPathOptions[],
};

Object.values<TPathOptionsEntry>(
	importAll.sync('../../../**/pathEntry.js')
).forEach((entry: TPathOptionsEntry) => {
	const menuCfg = {
		...entry.default,
		to: entry.default.path,
	};

	if (entry.default.menuOrder) {
		if (entry.default.ownApp) {
			appMenuEntries.ownAppMenus.push(menuCfg);
		} else if (entry.default.menuSection) {
			appMenuEntries.sectionedMenus.push(menuCfg);
		} else {
			appMenuEntries.mainAppMenus.push(menuCfg);
		}
	}
});

const sortMenu = (menuList: any) => sortBy(menuList, 'menuOrder');

const appMainMenus = sortMenu(appMenuEntries.mainAppMenus) as TPathOptions[];
const ownAppMenus = sortMenu(appMenuEntries.ownAppMenus) as TPathOptions[];
const sectionedMenus = sortMenu(appMenuEntries.sectionedMenus);

export const getAppRoutes = () => {
	return {
		appMainMenus,
		ownAppMenus,
		sectionedMenus,
	};
};
