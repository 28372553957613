import { Flex, Icon, InlineLoading, Label } from '@zeal/web-ui';
import React from 'react';
import { PointsWrapper, ZealBalanceIconWrapper } from './StyledComponents';
import { useHistory } from 'react-router-dom';
import { useCredit } from '@data/TopUp';
import { formatCredit } from '../../../TopUp/utils';
import { useFeatureControl } from '../../FeatureControl/FeatureControl';

export const ZealCreditIcon: React.FC = () => (
	<ZealBalanceIconWrapper>
		<Icon
			iconName="sterlingSign"
			variant="solid"
			colorVariant="secondary"
			size="xs"
			fixedWidth
			transform="shrink-2"
		/>
	</ZealBalanceIconWrapper>
);

export default function ZealPoints() {
	const history = useHistory();
	const { data: credit, isLoading: isCreditLoading } = useCredit();

	const formattedCredit = isCreditLoading ? (
		<InlineLoading />
	) : (
		formatCredit(credit)
	);

	const { isFeatureVisible } = useFeatureControl('topup');

	const onPointsClick = () => {
		if (isFeatureVisible) {
			history.push('/credits');
		}
	};

	return (
		<PointsWrapper onClick={onPointsClick}>
			<Flex gap="sm" align="center">
				<ZealCreditIcon />
				<Label weight="semiBold">{formattedCredit}</Label>
			</Flex>
		</PointsWrapper>
	);
}
