import { TPathOptions } from '../App/types/types';

const premiumDashboardPathOptions: TPathOptions = {
	apiKey: 'premium_dashboard',
	path: '/premium',
	label: 'home',
	iconName: 'house',
	menuOrder: 1,
	ownApp: false,
};

export default premiumDashboardPathOptions;
