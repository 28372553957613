import { TPathOptions } from '../App/types/types';

const tipsPathOptions: TPathOptions = {
	apiKey: 'teams:tip_distribution',
	path: '/tips',
	label: 'tips',
	iconName: 'userGroup',
	menuOrder: 8,
	ownApp: false,
	subMenu: [
		{
			label: 'history',
			path: '/tips/history',
		},
		{
			label: 'tip_distribution',
			path: '/tips/distribution',
		},
	],
};

export default tipsPathOptions;
