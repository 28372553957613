import { TPathOptions } from '../App/types/types';

const freemiumDashboardPathOptions: TPathOptions = {
	apiKey: 'freemium_dashboard',
	path: '/freemium',
	label: 'home',
	iconName: 'house',
	menuOrder: 1,
	ownApp: false,
};

export default freemiumDashboardPathOptions;
