import { TPathOptions, RouteFlags } from '../App/types/types';

const wheelOfFortunePathOptions: TPathOptions = {
	apiKey: 'wheel_of_fortune',
	path: '/wheelOfFortune',
	label: 'wheeloffortune',
	iconName: 'tireFlat',
	menuOrder: 7,
	ownApp: true,
  excludedFor:[],
	subMenu: [
		{
			path: '/wheelOfFortune/wheels',
			label: 'wheels',
		},
		{
			path: '/wheelOfFortune/archive',
			label: 'archive',
		},
		{
			path: '/wheelOfFortune/report',
			label: 'reports',
		}
	]
};

export default wheelOfFortunePathOptions;
