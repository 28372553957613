import { TPathOptions, RouteFlags } from '../App/types/types';

const articlePathOptions: TPathOptions = {
	apiKey: 'articles',
	path: '/articles',
	label: 'articles',
	iconName: 'newspaper',
	menuOrder: 8,
	ownApp: true,
};

export default articlePathOptions;
