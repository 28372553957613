import { TPathOptions, RouteFlags } from '../App/types/types';

const transactionsPathOptions: TPathOptions = {
	apiKey: 'transactions',
	menuOrder: 9,
	ownApp: false,
	path: '/visits-management',
	label: 'transactions',
	iconName: 'circleCheck',
	subMenu: [
		{
			path: '/visits-management',
			label: 'transactions',
		},
		{
			path: '/visits-management/receipts',
			label: 'receipts',
			excludedFor: [RouteFlags.SHOW_RECEIPTS_DISABLED],
		},
		{
			path: '/visits-management/redemptions',
			label: 'redemptions',
			excludedFor: [RouteFlags.SHOW_REDEMPTIONS_DISABLED],
		},
	],
};

export default transactionsPathOptions;
