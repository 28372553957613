import { TApiGetEmaiResponse, TMagicLinkForm } from '@app/MagicLinkLogin/types';
import { ApiRequest } from '@zeal/zeal-lib';
import { ENDPOINTS } from '../endpoints';

export async function getMagicLinkEmail(token: string) {
	return new ApiRequest(ENDPOINTS.magicLink.getEmail(token))
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.GET<ApiResponse<TApiGetEmaiResponse>>({ noAuth: true })
		.then((payload) => payload);
}

export async function sendMagicLinkCode(payload: { token: string }) {
	return new ApiRequest(ENDPOINTS.magicLink.sendCode)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload), { noAuth: true });
}

export async function verifyMagicLinkCode(payload: {
	token: string;
	code: string;
}) {
	return new ApiRequest(ENDPOINTS.magicLink.verifyCode(payload.token))
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.addQuery('code', payload.code)
		.GET({ noAuth: true })
		.then((payload) => payload);
}

export async function magicLinkregister(payload: TMagicLinkForm) {
	return new ApiRequest(ENDPOINTS.magicLink.register)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST<ApiResponse<{ is_email_verified: boolean }>>(
			JSON.stringify(payload),
			{ noAuth: true }
		);
}

export async function magicLinkUpdatePassword(payload: {
	token: string;
	password: string;
}) {
	return new ApiRequest(ENDPOINTS.magicLink.updatePassword)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload), { noAuth: true });
}

export async function magicLinkVerifyEmail(payload: { token: string }) {
	return new ApiRequest(ENDPOINTS.magicLink.verifyEmail)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload), { noAuth: true });
}
