import { TPathOptions, RouteFlags } from '../App/types/types';

export const path = '/gift-friend';

const giftFriendPathOptions: TPathOptions = {
	apiKey: 'gift_friend',
	path,
	label: 'gift_a_friend',
	iconName: 'heart',
	menuOrder: 7,
	ownApp: false,
	excludedFor: [RouteFlags.GIFT_FRIEND_DISABLED],
};

export default giftFriendPathOptions;
