import { TPathOptions } from '../App/types/types';

const rewardsPathOptions: TPathOptions = {
	apiKey: 'rewards',
	path: '/rewards',
	label: 'rewards',
	iconName: 'gift',
	menuOrder: 1,
	ownApp: false,
};

export default rewardsPathOptions;
