import React from 'react';
import { Image, Label, Padding } from '@zeal/web-ui';
// @ts-ignore
import ZealLogo from './assets/zeal-logo.svg';
import FreemiumLanguageSelector from '@app/AppContainer/components/FreemiumLanguageSelector';
import { useTranslation } from 'react-i18next';
import './header.css';

export const APP_WEBSITE = process.env.REACT_APP_WEBSITE ?? '';

export default function NavMenu() {
	const { t } = useTranslation('magicLink');

	return (
		<header className="bg-white wrapper">
			<div className="content sectionContainer">
				<div className="navLinks">
					<div className="logo">
						<Image src={ZealLogo} />
					</div>
				</div>
				<div className="flex items-center gap-4 xl:gap-8">
					<a
						href={`${APP_WEBSITE}/contact-us`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<Label.Mid300 variant="primary">{t('contact_us')}</Label.Mid300>
					</a>
					<a
						href={`${APP_WEBSITE}/about`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<Label.Mid300 variant="primary">{t('about')}</Label.Mid300>
					</a>
					<FreemiumLanguageSelector />
				</div>
			</div>
		</header>
	);
}
