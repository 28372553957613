import { useDebugValue } from 'react';

import { generatedKeys } from '@app/data/queryKeysFactory';
import { useMutation, useQuery } from '@app/QueryClient';
import { TApiGetEmaiResponse } from '@app/MagicLinkLogin/types';
import {
	getMagicLinkEmail,
	magicLinkregister,
	magicLinkUpdatePassword,
	magicLinkVerifyEmail,
	sendMagicLinkCode,
	verifyMagicLinkCode,
} from '@app/Services/MagicLink/magicLink';
import { useTranslation } from 'react-i18next';

const { magicLink } = generatedKeys;

export function useGetMagicLinkEmail(token: string, opt?: any) {
	const q = useQuery({
		queryFn: () => getMagicLinkEmail(token),
		staleTime: Infinity,
		retry: 2,
		queryKey: magicLink?.getEmail?.({}),
		enabled: !!token,
		...opt,
	});

	useDebugValue(q);

	return { ...q, data: q.data as TApiGetEmaiResponse };
}

export function useSendMagicLinkCode(opt?: any) {
	const { t } = useTranslation('magicLink');
	const mutation = useMutation(sendMagicLinkCode, {
		...opt,
		moduleName: t('title'),
		operation: 'add',
		disableToaster: true,
	});

	const { mutate } = mutation;

	return {
		...mutation,
		doSendMagicLinkCode: mutate,
	};
}

export function useVerifyMagicLinkCode(opt?: any) {
	const { t } = useTranslation('magicLink');
	const mutation = useMutation(verifyMagicLinkCode, {
		...opt,
		moduleName: t('title'),
		operation: 'add',
		disableToaster: true,
	});

	const { mutate } = mutation;

	return {
		...mutation,
		doVerifyMagicLinkCode: mutate,
	};
}

export function useMagicLinkRegister(opt?: any) {
	const { t } = useTranslation('magicLink');
	const mutation = useMutation(magicLinkregister, {
		...opt,
		moduleName: t('title'),
		operation: 'add',
		disableToaster: true,
	});

	const { mutateAsync } = mutation;

	return {
		...mutation,
		doMagicLinkRegister: mutateAsync,
	};
}

export function useMagicLinkUpdatePassword(opt?: any) {
	const { t } = useTranslation('magicLink');
	const mutation = useMutation(magicLinkUpdatePassword, {
		...opt,
		moduleName: t('title'),
		operation: 'update',
		disableToaster: true,
	});

	const { mutateAsync } = mutation;

	return {
		...mutation,
		doMagicLinkUpdatePassword: mutateAsync,
	};
}

export function useMagicLinkVerifyEmail(opt?: any) {
	const { t } = useTranslation('magicLink');
	const mutation = useMutation(magicLinkVerifyEmail, {
		...opt,
		moduleName: t('title'),
		operation: 'update',
		disableToaster: true,
	});

	const { mutate } = mutation;

	return {
		...mutation,
		doMagicLinkVerifyEmail: mutate,
	};
}
