import { TPathOptions } from '../App/types/types';

const menuManagementPathOptions: TPathOptions = {
	apiKey: 'menu',
	path: '/menu-management',
	label: 'menu',
	iconName: 'folders',
	menuOrder: 1,
	ownApp: true,
};

export default menuManagementPathOptions;
