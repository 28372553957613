import { TPathOptions } from '../App/types/types';

const branchesPathOptions: TPathOptions = {
	apiKey: 'branches',
	path: '/branches',
	label: 'branches',
	iconName: 'store',
	menuOrder: 6,
	ownApp: false,
};

export default branchesPathOptions;
