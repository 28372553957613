import React, { useMemo } from 'react';
import { Flex, Label } from '@zeal/web-ui';
import { useTranslation } from 'react-i18next';
import { useMagicLinkFormContext } from './FormContext';
import { MagicLinkStepEnum } from '../../types';
// @ts-ignore
import ZealIcon from '../assets/zeal-icon.svg';
// @ts-ignore
import ZealName from '../assets/zeal-name.svg';
import { useFetchTranslation } from '@app/hooks';

export default function FormHeader() {
	const { t } = useTranslation('magicLink');

	const { isTranslationsLoading } = useFetchTranslation('magicLink');

	const { step } = useMagicLinkFormContext();

	const formTitle = useMemo(() => {
		return {
			[MagicLinkStepEnum.EmailRegistration]: t('step_1_title'),
			[MagicLinkStepEnum.CodeVerification]: t('step_2_title'),
			[MagicLinkStepEnum.CreatePassword]: t('step_3_title'),
			[MagicLinkStepEnum.LoginToAccount]: t('step_5_title'),
			[MagicLinkStepEnum.AccountVerified]: t('step_6_title'),
		};
	}, [t, isTranslationsLoading]);

	const formSubtitle = useMemo(() => {
		return {
			[MagicLinkStepEnum.EmailRegistration]: t('step_1_description'),
			[MagicLinkStepEnum.CodeVerification]: t('step_2_description'),
			[MagicLinkStepEnum.CreatePassword]: t('step_3_description'),
			[MagicLinkStepEnum.LoginToAccount]: t('step_5_description'),
			[MagicLinkStepEnum.AccountVerified]: t('step_6_description'),
		};
	}, [t, isTranslationsLoading]);

	return (
		<Flex isColumn gap="12" align="center">
			<Flex
				align="center"
				justify="center"
				styles={{
					gap: '22.79px',
				}}
			>
				<Flex
					styles={{
						width: '76px',
						height: '53px',
					}}
				>
					<img src={ZealIcon} />
				</Flex>
				<Flex
					styles={{
						width: '80px',
						height: '28px',
					}}
				>
					<img src={ZealName} />
				</Flex>
			</Flex>
			{step !== MagicLinkStepEnum.VerificationSent && (
				<Flex
					isColumn
					gap="2"
					align="center"
					justify="center"
					styles={{
						width: '504px',
					}}
				>
					<Label.Big600 variant="default" textAlign="center">
						{formTitle[step]}
					</Label.Big600>
					<Label.Thin300 variant="subdued" textAlign="center">
						{formSubtitle[step]}
					</Label.Thin300>
				</Flex>
			)}
		</Flex>
	);
}
