import { TPathOptions } from '../App/types/types';

const freemiumLoyaltyPathOptions: TPathOptions = {
	apiKey: 'loyalty',
	exact: false,
	menuOrder: 3,
	ownApp: false,
	path: '/loyalty',
	label: 'loyalty_program',
	excludedFor: [],
	iconName: 'gift',
	subMenu: [
		{
			featureApiKey: 'loyalty:loyalty_settings',
			label: 'loyalty_settings',
			path: '/loyalty/settings',
		},
		{
			featureApiKey: 'loyalty:loyalty_reports',
			label: 'loyalty_reports',
			path: '/loyalty/reports',
		},
		{
			featureApiKey: 'loyalty:loyalty_downloads',
			label: 'downloads',
			path: '/loyalty/downloads',
		},
	],
};

export default freemiumLoyaltyPathOptions;
