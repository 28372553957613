import { TPathOptions } from '../App/types/types';

const storiesPathOptions: TPathOptions = {
	apiKey: 'stories',
	exact: false,
	menuOrder: 4,
	ownApp: true,
	path: '/stories',
	label: 'stories',
	iconName: 'circleDashed',
	subMenu: [
		{
			path: '/stories/page',
			label: 'stories',
		},
		{
			path: '/stories/archived',
			label: 'archived',
		}
	]
};

export default storiesPathOptions;
