import { TPathOptions } from '../App/types/types';

const topupPathOptions: TPathOptions = {
	apiKey: 'topup',
	path: '/credits',
	label: 'top_up',
	iconName: 'scroll',
	menuOrder: false,
	ownApp: false,
};

export default topupPathOptions;
